const clientLanguageDetector = {
    name: 'clientLanguageDetector',
    url: null,
    
    lookup(options) {
      if(typeof window === 'undefined'){
        return null
      }
      if (window.location.pathname.indexOf('/en') === 0) {
        return 'en';
      }
  
      return 'pl';
    },
  
    cacheUserLanguage(lng, options) {},
  }
  
  export default clientLanguageDetector;