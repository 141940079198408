import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import clientLanguageDetector from './clientLanguageDetector';

const languageDetector = new LanguageDetector();

languageDetector.addDetector(clientLanguageDetector);

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    // lng: 'auto',
    fallbackLng: 'pl',
    debug: false,
    // preload: ['pl', 'en'],
    defaultNS: 'translation',
    react: {
      transKeepBasicHtmlNodesFor: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'br', 'i', 'p', 'sup', 'li', 'ul', 'span', 'a'],
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: import.meta.env.VITE_I18N_TRANSLATIONS,
    },
    detection: {
      order: ['ssrLanguageDetector', 'clientLanguageDetector']
    }
  });

export default i18n;
export { languageDetector };