import './bootstrap';
import '../css/app.css';

import { createRoot, hydrateRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import * as ApolloPkg from '@apollo/client';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import route from '@ziggyJs';
import { Ziggy } from '@/ziggy';

const { ApolloClient, InMemoryCache, ApolloProvider } = ApolloPkg

const client = new ApolloClient({
  uri: import.meta.env.VITE_GRAPHQL_ENDPOINT,
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
});

createInertiaApp({
  title: (title) => `${title}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
  setup({ el, App, props }) {
    const global = globalThis;
    global.route = (name, params, absolute, config = Ziggy) => route(name, params, absolute, config);
    global.url = () => window.location;

    const root = createRoot(el);
    root.render(
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={client}>
          <App  {...props} />
        </ApolloProvider>
      </I18nextProvider>
    );

    // hydrateRoot(el,
    //   <div>
    //     <I18nextProvider i18n={i18n}>
    //       <ApolloProvider client={client}>
    //         <App  {...props} />
    //       </ApolloProvider>
    //     </I18nextProvider>
    //   </div>
    // );
  },
  progress: {
    color: '#4B5563',
  },
});
